import { Box, Button, Card, CardContent, Chip, FormControl, Grid, InputLabel, ListItemText, MenuItem, TextField, Typography, Select, Stack, FormHelperText } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from 'react';
import { Loader } from 'src/components/CustomLoader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import errorHandler from 'src/utils/errorHandler';
import { getNotificaiton, updateNotificaiton } from 'src/api/notificationService';
import { toast } from 'react-toastify';

function NotificationList() {
    const [loading, setLoading] = useState(false)


    const formik = useFormik({
        initialValues: {
            expireAlert: []
        },
        validationSchema: Yup.object({
            expireAlert: Yup.array().min(1, "Expiration alert days is required")
        }),
        onSubmit: (values, { setSubmitting }) => {
            onSubmit(values, setSubmitting)
        }
    })

    const fetchNotification = async () => {
        try {

            let res = await getNotificaiton()
            formik.setFieldValue("expireAlert", res?.data?.data?.expireAlert)

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        setLoading(true)
        fetchNotification()
    }, [])


    const onSubmit = async (values, setSubmitting) => {
        try {
            let res = await updateNotificaiton(values)
            toast.success(res?.data?.message, { toastId: "notification updated" })
            fetchNotification()
        } catch (error) {
            errorHandler(error)
        } finally {
            setSubmitting(false)
        }
    }


    const { values, errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;

    const expirationOptions = ["5 hours before", "1 day before", "3 days before"]

    const handleDelete = (idx) => {
        let newValues = values.expireAlert?.filter((_, i) => i != idx)
        setFieldValue("expireAlert", newValues)
    }


    return (
        <Card>
            <CardContent>
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Notification Management
                </Typography>
                {loading ? <Loader /> :
                    <FormikProvider value={formik} style={{ padding: "34px 30px" }}>

                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item lg={12} mb={2}>
                                    <FormControl fullWidth error={Boolean(touched.expireAlert && errors.expireAlert)}>
                                        <InputLabel id="demo-mutiple-chip-checkbox-label">
                                            Expiration Alert Days
                                        </InputLabel>
                                        <Select
                                            labelId="demo-mutiple-chip-checkbox-label"
                                            id="demo-mutiple-chip-checkbox"
                                            multiple
                                            label="Expiration Alert Days"
                                            value={values.expireAlert}
                                            onChange={(e) => setFieldValue("expireAlert", e.target.value)}
                                            IconComponent={KeyboardArrowDownIcon}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value, idx) => (
                                                        <Chip
                                                            key={idx}
                                                            label={value}
                                                            clickable
                                                            deleteIcon={
                                                                <CancelIcon
                                                                    onMouseDown={(event) => event.stopPropagation()}
                                                                />
                                                            }
                                                            onDelete={() => { handleDelete(idx) }}
                                                            onClick={() => { handleDelete(idx) }}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {expirationOptions.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(touched.expireAlert && errors.expireAlert) && <FormHelperText>{touched.expireAlert && errors.expireAlert}</FormHelperText>}
                                    </FormControl>

                                </Grid>
                            </Grid>


                            <Button
                                size="large"
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                sx={{ my: 2, }}
                            >
                                Save
                            </Button>

                        </Form>
                    </FormikProvider>
                }
            </CardContent>
        </Card>
    )
}

export default NotificationList;


