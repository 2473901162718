import React from 'react'
import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';

export default function TableFilter({value, setValue, data, filterName}) {

    const handleRemoveItem = (idx) =>{
        const currentValue = [...value];
        currentValue.splice(idx,1);
        setValue([...currentValue])
    }

    return (
        <FormControl variant='outlined' sx={{ minWidth: "150px", marginRight: "10px" }} size='small'>
            <InputLabel id="demo-select-small-label">{filterName}</InputLabel>
            <Select
                labelId="demo-mutiple-chip-checkbox-label"
                id="demo-mutiple-chip-checkbox"
                multiple
                label={filterName}
                value={value}
                onChange={(e) => {
                    const val = e.target.value;
                    setValue([...val]);
                }}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => (
                    <div>
                        {selected.map((value, idx) => (
                            <Chip
                                key={value}
                                label={data.find(item => item.value == value).label}
                                clickable
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                    />
                                }
                                onDelete={() => { handleRemoveItem(idx) }}
                                onClick={() => { handleRemoveItem(idx) }}
                            />
                        ))}
                    </div>
                )}
            >
                {data.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        <Checkbox checked={value.includes(item.value)} />
                        <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
