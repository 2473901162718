import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Tab,
  Tabs,
  Grid,
  Typography,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EdiorJodit from "../../components/editor/editor";
import { Icon } from "@iconify/react";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import cmsService from "src/api/cmsService";
import { useNavigate } from "react-router-dom";
import { tostE, tostS } from "src/utils/Toast";
import { Loader } from "src/components/CustomLoader";
import { ErrorMessage } from "src/utils/errorMessage";


const TermsCondition = () => {
  const navigate = useNavigate()
  const [cmsData, setCmsData] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true)
  const slugname = window.location.pathname == "/cms/merchant-terms-conditions" ? "merchant-terms" : "terms";

  function removeHtmlTags(text) {
    const clean = text?.replace(/<[^>]*>/g, "");
    return clean;
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: cmsData || "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const textContent = removeHtmlTags(cmsData);
        if (textContent?.length < 10) {
          tostE(ErrorMessage.min10Char);
          return;
        }

        const cmsDataa = {
          description: cmsData,
          slug: slugname,
          id: id,
        };

        let res = await cmsService.updateCms(cmsDataa);
        tostS(res?.data?.message);
        // navigate("/dashboard")
      } catch (error) {
        errorHandler(error);
      } finally {
        setSubmitting(false)
      }
    },
  });
  const { errors, touched, values, getFieldProps, setFieldValue } = formik;
  const fetchTerms = async () => {
    try {
      let params = {
        slug: slugname,
      };
      setLoading(true)
      const resp = await cmsService.getOneCms(params);
      setCmsData(resp?.data?.data?.description);
      setId(resp?.data?.data?.id);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchTerms();
  }, [slugname]);



  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {slugname == "merchant-terms" ? "Merchant" : "User"} Terms & Conditions
          </Typography>
        </Stack>

        <Container>
          <Card>
            <CardContent>
              {loading ? <Loader /> :
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>

                    <Grid item xs={12} md={6}>
                      <FormLabel sx={{ display: "block" }}>Description</FormLabel>

                      <EdiorJodit
                        onChange={(html) => {
                          setCmsData(html);
                        }}
                        data={cmsData}
                        placeholder="Terms & Conditions"
                      />
                    </Grid>
                    <Stack
                      spacing={2}
                      mt={5}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        startIcon={<Icon icon="fa-solid:save" />}

                      >
                        Save
                      </Button>
                    </Stack>
                  </Form>
                </FormikProvider>
              }
            </CardContent>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default TermsCondition;
