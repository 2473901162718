import React, { useMemo, useRef, useState } from "react";
import JoditEditor, { Jodit } from "jodit-react";

const EdiorJodit = (props) => {
  const { onChange, data, placeholder } = props;

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Description",
      minHeight: 400,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      cleanHTML: {
        fillEmptyParagraph: true
      },
      buttons: [
        "bold",
        "source",
        "italic",
        "|",
        "ul",
        "ol",
        "|",
        "fontsize",
        "paragraph",
        "underline",
        "hr",
      ],
      buttonsLG: [
        "bold",
        "italic",
        "|",
        "ul",
        "ol",
        "|",
        "fontsize",
        "paragraph",
        "underline",
        "hr",
      ],
      buttonsMD: [
        "bold",
        "italic",
        "|",
        "ul",
        "ol",
        "|",
        "fontsize",
        "paragraph",
        "underline",
        "hr",
      ],
      buttonsSM: [
        "bold",
        "italic",
        "|",
        "ul",
        "ol",
        "|",
        "fontsize",
        "paragraph",
        "underline",
        "hr",
      ],
      buttonsXS: [
        "bold",
        "italic",
        "|",
        "ul",
        "ol",
        "|",
        "fontsize",
        "paragraph",
        "underline",
        "hr",
      ],

      // toolbarAdaptive: true,
      // allowResizeX: false,
      // allowResizeY: false,
      // buttons: [
      // 'source', '|',
      // 'bold',
      // 'strikethrough',
      // 'italic', '|',
      // 'ul',
      // 'ol', '|',
      // 'outdent', 'indent',  '|',
      // 'font',
      // 'fontsize',
      // 'paragraph', '|',
      // 'table',
      // 'align', 'undo', 'redo', '|',
      // 'fullsize',
      // 'brush',
      // 'underline',
      // 'image',
      // 'video',
      // 'link', '|',
      // 'hr',
      // 'eraser',
      // 'copyformat', '|',
      // 'symbol',
      // 'print',
      // 'about'
      // ],
    };
  }, [placeholder]);
  return (
    <>
      <JoditEditor
        ref={editor}
        value={data}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => onChange(newContent)}
        onChange={(newContent) => { }}

      />
    </>
  );
};

export default EdiorJodit;
