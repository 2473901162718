import AxiosInterceptor from "src/utils/AxiosInterceptor";

export const getAllPublishedCoupon = async (payload) => {
    return await AxiosInterceptor().post("coupon/getAllCouponPublished", payload)
}
export const getPublishedCoupon = async (id, timeZone) => {
    return await AxiosInterceptor().get(`coupon/getOneCouponPublished/${id}?currentDate=${new Date().toISOString()}`)
}
export const updatePublishedCoupon = async (payload) => {
    return await AxiosInterceptor().post(`coupon/updateCouponPublished`, payload)
}

export const changeStatusApi = "coupon/changeStatusPublished"