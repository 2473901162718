import { toast } from "react-toastify";
import CustomLoader from "src/components/CustomLoader";
import { tostE } from "./Toast";
import { values } from "lodash";
import moment from "moment";

export function capitalize(str) {
    if (typeof str !== 'string' || !str) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}


export function isInThePast(date) {
    const today = new Date();

    // 👇️ OPTIONAL!
    // This line sets the hour of the current date to midnight
    // so the comparison only returns `true` if the passed in date
    // is at least yesterday
    today.setHours(0, 0, 0, 0);

    return date < today;
}



export const getSiteDomain = ({ site }) => {
    try {
        return ((new URL(site.url)).origin).replace(/^http(s?):\/\//, '').replace(/\/$/, '');
    } catch (e) {
        return site.url.replace(/^http(s?):\/\//, '').replace(/\/$/, '');
    }
};


export const formatNumber = (amount) => {
    if (amount === undefined || amount === null) {
        return '';
    }
    return amount.toLocaleString();
};


// Check if member is a recent member, i.e. created in last 24 hours
export function isRecentMember({ member }) {
    if (!member?.created_at) {
        return false;
    }

    const now = new Date();
    const created = new Date(member.created_at);
    const diff = now.getTime() - created.getTime();
    const diffHours = Math.round(diff / (1000 * 60 * 60));

    return diffHours < 24;
}


export const safeCallback = (callback, arg1, arg2, arg3) => {
    if (!callback) {
        return;
    }

    if (arg3 !== undefined) {
        callback(arg1, arg2, arg3);
        return;
    }
    if (arg2 !== undefined) {
        callback(arg1, arg2);
        return;
    }
    callback(arg1);
};




export const runningOnBrowser = typeof window !== "undefined";

export const isBot =
    (runningOnBrowser && !("onscroll" in window)) ||
    (typeof navigator !== "undefined" && /(gle|ing|ro)bot|crawl|spider/i.test(navigator.userAgent));



export const supportsIntersectionObserver = runningOnBrowser && "IntersectionObserver" in window;

export const supportsClassList = runningOnBrowser && "classList" in document.createElement("p");

export const supportsCreateImageBitmap = runningOnBrowser && "createImageBitmap" in window;

export const supportsFetch = runningOnBrowser && "fetch" in window;

export const isHiDpi = runningOnBrowser && window.devicePixelRatio > 1;


export const validateImage = (file, extension, imageSize) => {
    let sizeIntoByte = imageSize ? (imageSize * 1024 * 1024) : (2 * 1024 * 1024)
    imageSize = imageSize || 2
    let validate = false
    if (file) {
        if (extension?.includes(file?.type)) {
            if (sizeIntoByte >= file.size) {
                validate = true
            } else {
                toast.error(`Image must be less than ${imageSize}mb`, {
                    toastId: "maximumSizeLimit"
                })
            }
        } else {
            toast.error(`Only the following formats are accepted: ${extension?.join(', ')}`, {
                toastId: "extionsionLimit"
            })
        }
    }

    return validate

}

export const handleCorruptValidate = (files) => {
    const promises = [];

    files.forEach(file => {
        promises.push(new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    resolve(file);
                };
                img.onerror = function () {
                    toast.error("Image is corrupted: " + file.name);
                };
                img.src = e.target.result;
            };
            reader.onerror = function () {
                reject("Error: Failed to read the file: " + file.name);
            };
            reader.readAsDataURL(file);
        }));
    });

    return Promise.all(promises);
};


export const handleSingleImage = (e, formikname, formik, setState, ext) => {
    let allowedExtension = ext || ["image/jpg", "image/jpeg", "image/png"]
    let validate = validateImage(e.target.files[0], allowedExtension)
    let uploadFile = e.target.files[0];
    if (validate == true) {

        const file = uploadFile
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    setState(URL.createObjectURL(uploadFile))
                    formik.setFieldValue([formikname], uploadFile);
                };
                img.onerror = function () {
                    toast.error("Image is corrupted", {
                        toastId: "corruptImage"
                    })
                };
                img.src = e.target.result;
            };
            reader.onerror = function () {
                console.log('Error: Failed to read the file.');
            };
            reader.readAsDataURL(file);
        }

    }
    e.target.value = null
}


export const handleMultipleImage = async (e, formikname, formik, state, setState, oldImages = 0, ext) => {

    let allFiles = e.target?.files
    allFiles = Object.keys(allFiles).map(key => allFiles[key]);
    let allowedExtension = ext || ["image/jpg", "image/jpeg", "image/png"]
    let newPreviewImage = []
    let newFormikImage = []
    let msgShown = false

    let validatedImages = 0
    allFiles = allFiles?.filter((singleFile, key) => {
        let validate = validateImage(singleFile, allowedExtension)
        if (validate) {
            let exceedMaxLimit = (formik.values[formikname].length + oldImages?.length + validatedImages >= 3) ? true : false
            if (exceedMaxLimit) {
                if (msgShown == false) {
                    tostE("Select upto 3 images")
                    msgShown = true
                }
            }
            else {
                validatedImages = validatedImages + 1
                return singleFile
            }

        }

    })

    let res = await handleCorruptValidate(allFiles)
    res.forEach((file) => {
        newFormikImage.push(file)
        newPreviewImage.push(URL.createObjectURL(file))
    })

    formik.setFieldValue([formikname], [...formik.values[formikname], ...newFormikImage])
    setState([...state, ...newPreviewImage])
    e.target.value = null

}


export const handleNumberInput = (e, formikname, formik, allowDecimal) => {

    const re = allowDecimal ? /^\d*\.?\d{0,2}$/ : /^[0-9\b]{0,9}$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        formik.setFieldValue([formikname], e.target.value)
    } else {
        return false
    }

}


export const getSrno = (currentPage, pageSize, index) => {
    return (currentPage == 1 ? 0 : (currentPage - 1) * pageSize) + index + 1
}


export const addToFixedIfDecimal = (value) => {
    value = Number(value)
    if (value % 1 !== 0) {
        return value?.toFixed(2)
    } else {
        return value
    }
}

export const showNameByRoles = (role, data) => {
    if (!role) {
        return "-"
    }
    else if (role == "2") {
        return data?.fullName
    }
    else if (role == "3") {
        return data?.representName
    } else {
        return "-"
    }
}


export const getPreviousDateFromToday = (year) => {
    if (year) {
        var currentDate = new Date();
        var previousDate = new Date();
        previousDate.setFullYear(currentDate.getFullYear() - year);
        return new Date(previousDate)
    }
}


export const showBusinessName = (role, data) => {
    if (!role) {
        return data?.name
    } else {
        return data?.businessName ? data?.businessName : "-"
    }
}


export const formatDate = (date, format = "DD/MM/YYYY") => {
    if (date) {
        return moment.utc(date).local().format(format)
    } else {
        return "-"
    }

}