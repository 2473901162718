import { useEffect, useState } from "react";
import errorHandler from 'src/utils/errorHandler';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { Loader } from "src/components/CustomLoader";
import { FILE_URL } from "src/utils/constants";
import { getPublishedCoupon } from "src/api/couponPublishedServices";
import moment from "moment";
import { addToFixedIfDecimal, formatDate } from "src/utils/helpers";

const View = ({ id, show, onHide }) => {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true)



    const couponTemplateData = async () => {
        try {
            setLoading(true)
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const response = await getPublishedCoupon(id, timezone);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }

    };

    useEffect(() => {
        couponTemplateData();
    }, []);


    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Details</DialogTitle>
            {loading ? <Loader /> :
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Coupon Image</Typography>
                            <Avatar
                                style={{ cursor: (data?.couponImage ? "pointer" : "") }}
                                alt={"coupon image"}
                                src={FILE_URL + data?.couponImage}
                                onClick={() => { if (data?.couponImage) { window.open((FILE_URL + data?.couponImage), "_blank") } }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Gallery Images</Typography>
                            <Stack display={"flex"} flexDirection={"row"} gap={1} flexWrap={"wrap"}>

                                {data?.galleryImages?.map((d) => (
                                    <Avatar
                                        style={{ cursor: (d ? "pointer" : "") }}
                                        alt={"gallery image"}
                                        src={FILE_URL + d}
                                        onClick={() => { if (d) { window.open((FILE_URL + d), "_blank") } }}
                                    />
                                ))}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Title</Typography>
                            <DialogContentText>
                                {data?.title}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Merchant Name</Typography>
                            <DialogContentText>
                                {data?.businessName}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Category</Typography>
                            <DialogContentText>
                                {data?.categoryName}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">List Price</Typography>
                            <DialogContentText>
                                ${data?.price}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Duration</Typography>
                            <DialogContentText>
                                {data?.duration} {data?.durationText}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Discount Percentage</Typography>
                            <DialogContentText>
                                {data?.disPercentage}%
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Discounted Price</Typography>
                            <DialogContentText>
                                ${data?.discountedPrice}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Number Of Slots</Typography>
                            <DialogContentText>
                                {data?.numberOfSlots}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Slot Start Time</Typography>
                            <DialogContentText>
                                {moment.utc(data?.slotStartTime, "HH:mm:ss").local().format("h:mm A")}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1"> Show</Typography>
                            <DialogContentText>
                                {data?.dailyShow ? "Daily" : ""}     {data?.weeklyShow ? data?.weeklyShow.join(', ') : ""}

                            </DialogContentText>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Weekly Show</Typography>
                            <DialogContentText>
                                {data?.weeklyShow ? data?.weeklyShow.join(', ') : ""}

                            </DialogContentText>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Published Date</Typography>
                            <DialogContentText>
                                {formatDate(data?.createdAt, "DD/MM/YYYY hh:mm a")}
                                {/* {moment.utc(data?.createdAt).local().format("DD/MM/YYYY hh:mm a")} */}

                                {/* {moment(data?.createdAt).format("DD/MM/YYYY hh:mm a")} */}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Start Date</Typography>
                            <DialogContentText>
                                {formatDate(data?.startDateTime)}
                                {/* {moment(data?.startDateTime).format("DD/MM/YYYY")} */}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">End Date</Typography>
                            <DialogContentText>
                                {formatDate(data?.endDateTime)}
                                {/* {moment(data?.endDateTime).format("DD/MM/YYYY")} */}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Availability</Typography>
                            <DialogContentText>
                                {data?.availability ? data?.availability : "-"}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="subtitle1">Description</Typography>
                            <DialogContentText>
                                {data?.description}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => onHide()}
                            type="submit"
                            style={{ backgroundColor: "white" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            }
        </Dialog>
    )
}

export default View