import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from "formik";
import { NOSPACE_REGEX } from "src/utils/constants";
import { rejectMerchant } from "src/api/merchantServices";
import { tostE, tostS } from "src/utils/Toast";

const RejectMerchant = ({ id, show, onHide, onReject }) => {

    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("Reason is required")
            .matches(NOSPACE_REGEX, "Please Enter a valid Reason")
            .min(2, "Please enter at least 2 characters")
            .max(250, "Reason must not be more than 250 characters."),
    });
    const formik = useFormik({
        initialValues: {
            reason: ""
        },
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            const payload = {
                id,
                rejectReason: values.reason
            }
            rejectMerchant(payload).then((res) => {
                if (res.status == 200) {
                    tostS(res?.data?.message);
                    resetForm()
                    onReject();
                }
            }).catch((err) => {
                console.log(err);
                if (err.response.data) {
                    tostE(err.response.data.message);
                }
            })
        }
    })

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setValues } = formik;

    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Reject Merchant</DialogTitle>
            <DialogContent dividers>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                rows={3}
                                name="reason"
                                label="Reason"
                                {...getFieldProps("reason")}
                                error={Boolean(touched.reason && errors.reason)}
                                helperText={touched.reason && errors.reason}
                            />

                        </Stack>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                loading={isSubmitting}
                                sx={{ my: 2 }}
                            >
                                Reject
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => onHide()}
                                type="button"
                                style={{ backgroundColor: "white" }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </DialogContent>
        </Dialog>
    );
};

export default RejectMerchant;
