import { Button, Stack } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const AddEditBtn = ({ id, isSubmitting, navigateUrl }) => {
    const navigate = useNavigate()

    return (
        <Stack display={"flex"} flexDirection={"row"} gap={3} mt={3}>
            <Button
                size="large"
                type="submit"
                variant="contained"
                // loading={String(isSubmitting)}
                disabled={isSubmitting ? true : false}
            >
                {id ? "Update" : "Save"}
            </Button>
            <Button
                size="large"
                type="button"
                variant="contained"
                onClick={() => {
                    if (!isSubmitting) {
                        navigate(navigateUrl);
                    }
                }}
            >
                Cancel
            </Button>
        </Stack>
    )
}

export default AddEditBtn