import React, { useEffect, useState } from 'react'
import complaintApi, { commplaintChangeStatusApi } from 'src/api/complaintService'
import errorHandler from 'src/utils/errorHandler'
import { Card, CardContent, CardHeader } from "@mui/material";
import DataTable from 'react-data-table-component';
import { formatDate, getSrno, showBusinessName, showNameByRoles } from 'src/utils/helpers';
import moment from 'moment';
import SwitchBtn from 'src/components/SwitchBtn';
import AddComplaint from './AddComplaint';
import CustomLoader from 'src/components/CustomLoader';
import ViewComplaint from './view';
import ComplaintStatus from './complaintStatus';
import { COMPLAINT_ROLES } from 'src/utils/constants';



const ComplaintList = () => {

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
    })


    const fetchComplaintList = async () => {
        try {
            setLoading(true)
            const res = await complaintApi.getAllComplaint(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchComplaintList()
    }, [payload])



    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => getSrno(payload.pageNumber, payload.pageSize, index)
        },
        {
            name: "Role",
            selector: (row) => COMPLAINT_ROLES?.filter((roles) => roles?.value == row?.role)?.[0]?.label
        },
        {
            name: "Name",
            selector: (row) => showNameByRoles(row?.role, row)
        },
        {
            name: "Business Name",
            selector: (row) => showBusinessName(row?.role, row)
            // selector: (row) => row?.businessName || "-"
        },
        {
            name: "Email",
            selector: (row) => row?.email
        },
        {
            name: "Phone Number",
            selector: (row) => row?.phoneNumber
        },

        {
            name: "Complaint Date",
            selector: (row) => formatDate(row.createdAt, "DD/MM/YYYY hh:mm a")
            // selector: (row) => row?.createdAt ? moment().format("DD/MM/YYYY hh:mm a") : ""
        },
        {
            name: "Status",
            selector: (row) => <ComplaintStatus status={row.status} id={row?.id} comment={row?.comment} callBack={fetchComplaintList} />
        },
        {
            name: "Action",
            selector: (row) => (
                <>
                    <ViewComplaint id={row?.id} />
                    {/* {row.status == "0" && !row?.comment ? <AddComplaint id={row?.id} callBack={fetchComplaintList} /> : ""} */}
                </>
            )
        }

    ]



    return (
        <>
            <Card>
                <CardHeader
                    title="Complaint Management"
                    titleTypographyProps={{ variant: "h4" }}
                />

                <CardContent>
                    <DataTable
                        columns={columns}
                        data={paginData.data}
                        pagination
                        paginationServer
                        paginationPerPage={payload.pageSize}
                        paginationTotalRows={paginData.totalCount}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowsPerPage, pageNumber: currentPage });
                        }}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}

                    />

                </CardContent>

            </Card>

        </>
    )
}

export default ComplaintList