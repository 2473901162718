import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import errorHandler from 'src/utils/errorHandler'
import { getSrno } from 'src/utils/helpers'
import { Box, Button, Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import DataTable from "react-data-table-component";
import CustomLoader from 'src/components/CustomLoader';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from "@mui/icons-material/Visibility";


const FeedbackListing = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: ""
    })
    const [viewModal, setViewModal] = useState({
        show: false,
        id: ""
    })

    const fetchFeedback = async () => {
        try {
            setLoading(true)
            // let res = await couponTemplateServices.getAllPublishedCoupon(payload)
            // setPaginData({
            //     data: res?.data?.data?.result,
            //     totalCount: res?.data?.data?.totalRecords
            // })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            name: "Sr.no",
            selector: (row, index) => getSrno(payload.pageNumber, payload.pageNumber.pageSize, index)
        },
        {
            name: "Name",
            selector: (row) => row?.name
        },
        {
            name: "Email Id",
            selector: (row) => row?.name
        },
        {
            name: "Received Date",
            selector: (row) => row?.name
        },
        {
            name: "Status",
            selector: (row) => row?.name
        },

        {
            name: <div >Action</div>,
            cell: (row) => (
                <>
                    <Tooltip title="View" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setViewModal({
                                    show: true,
                                    id: row.id
                                })
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ]

    useEffect(() => {
        fetchFeedback()
    }, [payload])

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Feedback Management"
                ></CardHeader>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Input
                        label="Search"
                        variant="standard"
                        value={payload?.searchItem}
                        placeholder="Search"
                        onChange={(e) => setPayload({ ...payload, searchItem: e.target.value })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear-search"
                                    onClick={() => setPayload({ ...payload, searchItem: "" })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        }

                    />
                    <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ ml: 3, mr: 3 }}
                        onClick={() => navigate("/coupon-templates/add")}
                    >
                        Add
                    </Button>
                </Box>

                <CardContent>
                    <DataTable
                        columns={columns}
                        data={[{}]}
                        // data={paginData.data}
                        pagination
                        paginationServer
                        paginationTotalRows={paginData.totalPages}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        paginationPerPage={payload.pageSize}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        // paginationTotalRows={data ? data.length : 0}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowsPerPage, pageNumber: currentPage });
                        }}
                    />
                </CardContent>
            </Card>
        </>
    )
}

export default FeedbackListing