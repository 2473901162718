import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Paper, Stack, TextField, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {  NOSPACE_REGEX, NOSPACE_REGEX_EMAIL, PASSWORDS_REGEX } from '../../../utils/constants';
import errorHandler from '../../../utils/errorHandler';
import cmsService from "src/api/cmsService";
import EdiorJodit from 'src/components/editor/editor';
import { useState } from 'react';
import { tostS } from 'src/utils/Toast';


const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Question is required")
    .matches(NOSPACE_REGEX, "Please Enter a valid Question")
    .min(2, "Please enter at least 2 characters")
    .max(100, "Question must not be more than 100 characters."),
});


export default function AddFaq() {
    const navigate = useNavigate();
    const { state } = useLocation();
  const [cmsData, setCmsData] = useState("");

   
    const formik = useFormik({
        initialValues: { title: '',description:'' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            cmsService
              .addFaq({
                title: values.title,
                description: cmsData,
              })
              .then((res) => {
                tostS(res?.data?.message);
                navigate("/faq");
                setSubmitting(false);
              })
              .catch((err) => {
                errorHandler(err);
                setSubmitting(false);
              });

        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
      <>
        <Card>
          <CardContent>
            <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
              <Typography variant="h4" sx={{ mb: 2 }} align="start">
                Add FAQ
              </Typography>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    name="title"
                    label="Question"
                    {...getFieldProps("title")}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  <EdiorJodit
                    onChange={(html) => {
                      setCmsData(html);
                    }}
                    data={cmsData}
                    placeholder="Answer"
                  />
                  {/* <TextField
                    name="description"
                    label="Description"
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  /> */}
                </Stack>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ my: 2 }}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    if(!isSubmitting)
                      navigate("/faq");
                  }}
                  sx={{ my: 2, ml: 4 }}
                >
                  Cancel
                </Button>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </>
    );
}
