import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { MESSAGE } from '../../utils/validationMessage';
import { EMAIL_REGEX, FILE_URL, GENDER_OPTIONS, MOBILE_REGEX, NOSPACE_REGEX, NOSPACE_REGEX_EMAIL, PASSWORDS_REGEX } from '../../utils/constants';
import errorHandler from '../../utils/errorHandler';
import apiUsers from 'src/api/usersService';
import { complexEmailRegex } from 'src/utils/emailCheck';
import { tostS } from 'src/utils/Toast';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { getPreviousDateFromToday, isComponentLoading } from 'src/utils/helpers';
import CustomLoader, { Loader } from 'src/components/CustomLoader';
import AddEditBtn from 'src/components/addEditBtn';
import GoogleAutoCompleteComp from 'src/components/GoogleAutoCompleteComp';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function EditUser() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(true)
        apiUsers.getOneUser(id).then((res) => {
            if (res.status == 200) {
                setUser({ ...res.data.data });
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

    }, [])


    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('FirstName is required').matches(NOSPACE_REGEX, "Please enter a valid first name").min(2, 'Please enter atleast 2 characters').max(50, 'First name must not be more than 50 characters.'),
        lastName: Yup.string().required('LastName is required').matches(NOSPACE_REGEX, "Please enter a valid last name").min(2, 'Please enter atleast 2 characters').max(50, 'Last name must not be more than 50 characters.'),
        phoneNumber: Yup.string().required('Phone Number is required').matches(MOBILE_REGEX, "Please Enter a valid phone number"),
        email: Yup.string().required("Email  is required").trim().matches(EMAIL_REGEX, "Please enter a valid email address").max(255),
        dob: Yup.string().required("Date of Birth is required"),
        gender: Yup.string().required("Gender is required"),
        address: Yup.string().test("lat", "Address is requried", (values, data) => {
            if (data.parent.lat?.length > 0) {
                return true
            } else {
                return false
            }
        }).required("Address is required"),
        // address: Yup.string().required("Address is required"),
        // lat: Yup.string().required("Address is required"),




        // profileURL: Yup.string().required("Profile image is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: user.firstName ? user.firstName : "",
            lastName: user.lastName ? user.lastName : "",
            phoneNumber: user.phoneNumber ? user.phoneNumber : "",
            email: user.email ? user.email : "",
            dob: user.dob ? new Date(user.dob) : "",
            gender: user.gender ? user.gender : "",
            address: user.address ? user.address : "",
            lat: user.lat ? user.lat : "",
            lng: user.lng ? user.lng : "",
            imagePath: "user",
            profileImage: user.profileImage ? user.profileImage : "",
            profileURL: user.profileImage ? FILE_URL + user.profileImage : "",
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            const formData = new FormData();
            formData.append("id", id)
            formData.append("firstName", values.firstName)
            formData.append("lastName", values.lastName)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("email", values.email.trim())
            formData.append("dob", moment(values.dob).format("yyyy-MM-DD"))
            formData.append("gender", values.gender)
            formData.append("address", values.address)
            formData.append("lat", values.lat)
            formData.append("lng", values.lng)
            formData.append("imagePath", values.imagePath)
            formData.append("profileImage", values.profileImage)

            apiUsers
                .editUser(formData)
                .then((res) => {
                    tostS(res?.data?.message)
                    navigate('/user');
                    setSubmitting(false)
                })
                .catch((err) => {
                    errorHandler(err)
                    setSubmitting(false)
                });

        },
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setValues, setFieldValue } = formik;

    const handleUploadProfile = (event) => {
        const file = event.target.files[0];
        setValues({
            ...values,
            profileImage: file,
            profileURL: URL.createObjectURL(file)
        })
    }

    const handleDateChange = (date) => {
        setFieldValue("dob", date)
    }

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value)) {
            setValues({ ...values, phoneNumber: value });
        }
    }



    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 2 }} align="start">Edit User</Typography>
                    {loading ? <Loader /> :
                        <FormikProvider value={formik} style={{ padding: '34px 30px' }}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} sm={12}>
                                        <TextField
                                            fullWidth
                                            name="firstName"
                                            label="First Name"
                                            {...getFieldProps('firstName')}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <TextField
                                            fullWidth
                                            name="lastName"
                                            label="Last Name"
                                            {...getFieldProps('lastName')}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <TextField
                                            fullWidth
                                            name="phoneNumber"
                                            label="Phone Number"
                                            {...getFieldProps('phoneNumber')}
                                            onChange={(e) => { handlePhoneChange(e) }}
                                            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            label="Email"
                                            {...getFieldProps('email')}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <FormControl fullWidth error={Boolean(touched.dob && errors.dob)}>
                                            <DatePicker
                                                label="Date of Birth"
                                                format='dd-MMM-yyyy'
                                                maxDate={getPreviousDateFromToday(16)}

                                                value={values.dob}
                                                localeText={{
                                                    fieldMonthPlaceholder: () => "MMM"
                                                }}
                                                onChange={(date) => handleDateChange(date)}
                                                inputFormat="dd/MMM/yyyy"
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                slotProps={{
                                                    textField: {
                                                        readOnly: true,
                                                        onKeyDown: (e) => { e.preventDefault(); },
                                                        helperText: touched.dob && errors.dob,
                                                        error: Boolean(touched.dob && errors.dob),
                                                        onClick: () => { setOpen(true); }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <FormControl fullWidth error={Boolean(touched.gender && errors.gender)}>
                                            <InputLabel id="gender">Gender</InputLabel>
                                            <Select
                                                labelId='gender-field'
                                                label='Gender'
                                                id="gender"
                                                name="gender"
                                                {...getFieldProps("gender")}
                                            >
                                                {GENDER_OPTIONS.map((item) => (
                                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText> <ErrorMessage name={"gender"} /></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item lg={6} sm={12}>
                                        <TextField
                                            fullWidth
                                            name="address"
                                            label="Address"
                                            {...getFieldProps('address')}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Grid> */}

                                    <Grid item lg={6} sm={12}>
                                        <GoogleAutoCompleteComp
                                            label="Address"
                                            formikName="address"
                                            formik={formik}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Avatar alt={values.firstName} src={values.profileURL} />
                                            <Button component="label" variant="outlined" style={{ backgroundColor: "#FFF" }} startIcon={<CloudUploadIcon />}>
                                                Upload file
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    accept='image/*'
                                                    onChange={(e) => { handleUploadProfile(e) }}
                                                />
                                            </Button>
                                        </Stack>
                                        {errors.profileURL && touched.profileURL ? <span className="form-error">{errors.profileURL}</span> : null}
                                    </Grid>
                                </Grid>

                                <AddEditBtn id={id} isSubmitting={isSubmitting} navigateUrl={"/user"} />
                                {/* <Button size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 2 }}>
                                    Save
                                </Button>
                                <Button size="large" variant="contained" onClick={() => { navigate('/user') }} sx={{ my: 2, ml: 4 }}>
                                    Cancel
                                </Button> */}

                            </Form>
                        </FormikProvider>
                    }
                </CardContent>
            </Card>

        </>

    )
}
