import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import ConfirmModal from 'src/components/confirmModal';
import errorHandler from 'src/utils/errorHandler';
import complaintApi from 'src/api/complaintService';
import { COMPLAINT_OPTIONS } from 'src/utils/constants';
import { toast } from 'react-toastify';
import AddComplaint from './AddComplaint';


const ComplaintStatus = ({ status, id, callBack, comment }) => {
    const [show, setShow] = useState(false)
    const [newStatus, setNewStatus] = useState('')
    const [showCommentModal, setShowCommentModal] = useState(false)



    const handleSwitch = async () => {
        if (status == 0) {
            if (newStatus == 1 && !comment) {
                setShow(false)
                setShowCommentModal(true)
                return
            }
            try {
                let body = {
                    id: id,
                    status: String(newStatus)
                }
                const res = await complaintApi.changeStatus(body)
                toast.success(res?.data?.message)
                callBack()

            } catch (error) {
                errorHandler(error)
            }
        }
    }


    const getStatus = (status) => {
        return COMPLAINT_OPTIONS?.filter((comp) => comp?.value == status)?.[0]?.label
    }

    return (
        <>
            <Box sx={{ minWidth: 120, my: 1 }}>
                <FormControl sx={{}} fullWidth size="small">
                    {status == "0"
                        ? <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            disabled={status > 0}
                            onChange={(e) => {
                                if (e?.target?.value != status) {
                                    setShow(true)
                                    setNewStatus(e?.target?.value)
                                }
                            }}
                        >
                            {COMPLAINT_OPTIONS?.map((comp, idx) => (
                                <MenuItem value={comp.value} key={idx} disabled={status > 0} >{comp?.label}</MenuItem>
                            ))}
                        </Select>
                        : getStatus(status)
                    }
                </FormControl>
            </Box>
            {show && <ConfirmModal show={show} setShow={setShow} msg={"Are you sure want to change the status?"} handleSwitch={handleSwitch} />}
            {showCommentModal && <AddComplaint id={id} hideButton={true} setShowCommentModal={setShowCommentModal} newStatus={newStatus} callBack={callBack} />}
        </>
    )
}

export default ComplaintStatus