import PropTypes from "prop-types";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
// @mui
import { Box, List, Collapse, ListItem, ListItemText } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import React, { useState } from "react";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

// function NavItem({ item }) {
//   const { title, path, icon, info } = item;

//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to={path}
//       sx={{
//         "&.active": {
//           color: "text.primary",
//           bgcolor: "action.selected",
//           fontWeight: "fontWeightBold",
//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

//       <ListItemText disableTypography primary={title} />

//       {info && info}
//     </StyledNavItem>
//   );
// }

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  const {pathname} = useLocation();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}       
        sx={pathname==path ? {
          "&.active": {
            color: "text.primary",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }:{}}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItem button onClick={handleClick}>
          <ListItemText disableTypography primary={title} />
          {(children && children.length) ? (open ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : ""}
        </ListItem>
        {info && info}
      </StyledNavItem>
      <Collapse in={open} timeout="auto" unmountOnExit orientation="vertical">
        {(children && children.length) ? children.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <StyledNavItem
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      marginLeft: "20px",
                      "&.active": {
                        color: "text.primary",
                        bgcolor: "action.selected",
                        fontWeight: "fontWeightBold",
                      },
                    }}
                  >
                    <StyledNavItemIcon>
                      {item.icon && item.icon}
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={item.title} />
                  </StyledNavItem>
                </React.Fragment>
              );
            }) :""}
      </Collapse>
    </>
  );
}
