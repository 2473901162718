import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

// pages
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import LoginPage from "./pages/auth/LoginPage";
import ForgetForm from "./pages/auth/ForgetPage";
import UserManagement from "./pages/User Management";
import ChangePassword from "./pages/auth/ChangePassword";
import ResetPassword from "./pages/auth/ResetPasswordPage";
import AddUser from "./pages/User Management/AddUser";
import EditUser from "./pages/User Management/EditUser";
import TermsCondition from "./pages/Cms Management/TermsCondition";
import PrivacyPolicy from "./pages/Cms Management/PrivacyPolicy";
import FaqManagement from "./pages/Cms Management/faq/FaqManagement";
import AddFaq from "./pages/Cms Management/faq/AddFaq";
import EditFaq from "./pages/Cms Management/faq/EditFaq";
import CategoryManagement from "./pages/Category Management/CategoryManagement";
import AddCategory from "./pages/Category Management/AddCategory";
import EditCategory from "./pages/Category Management/EditCategory";
import MerchantManagement from "./pages/MerchantManagement/MerchantManagement";
import AddMerchant from "./pages/MerchantManagement/AddMerchant";
import GlobalSetting from "./pages/Global Setting";
import EditMerchant from "./pages/MerchantManagement/EditMerchant";
import EmailTemplateManagement from "./pages/EmailTemplateManagement";
import AddEmailTemplate from "./pages/EmailTemplateManagement/AddEmailTemplate";
import EditEmailTemplate from "./pages/EmailTemplateManagement/EditEmailTemplate";
import RegistrationAgreement from "./pages/Cms Management/RegistrationAgreement";
import NotificationList from "./pages/Notification management/Notification";
import CouponPublishedList from "./pages/coupon/couponPublished/List";
import CouponPublishedEdit from "./pages/coupon/couponPublished/Edit";
import CouponTemplateList from "./pages/coupon/couponTemplate/List";
import CouponTemplateEdit from "./pages/coupon/couponTemplate/Edit";
import FeedbackListing from "./pages/Feedback Management/List";
import FinePrint from "./pages/Cms Management/FinePrint";
import ComplaintList from "./pages/complaintManagement/List";
// import TabsContent from "./pages/contentManagement/privacyPolicy/TabContent";

export default function Router() {
  const isLoggedIn = useSelector((state) => state.auth.token);

  const PrivateRoutes = [
    {
      path: "/",
      element: <DashboardLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "/dashboard", element: <DashboardAppPage /> },
        { path: "/change-password", element: <ChangePassword /> },
        { path: "/global-setting", element: <GlobalSetting /> },
        {
          path: "cms",
          children: [
            {
              path: "user-terms-conditions",
              element: <TermsCondition />,
            },
            {
              path: "merchant-terms-conditions",
              element: <TermsCondition />,
            },
            {
              path: "user-privacy-policy",
              element: <PrivacyPolicy />,
            },
            {
              path: "merchant-privacy-policy",
              element: <PrivacyPolicy />,
            },
            {
              path: "reg-agreement",
              element: <RegistrationAgreement />,
            },
            {
              path: "fine-print",
              element: <FinePrint />,
            },
          ],
        },

        {
          path: "faq",
          children: [
            { index: true, element: <FaqManagement /> },
            { path: "add", element: <AddFaq /> },
            { path: "edit", element: <EditFaq /> },
          ],
        },
        {
          path: "category",
          children: [
            { index: true, element: <CategoryManagement /> },
            { path: "add", element: <AddCategory /> },
            { path: "edit", element: <EditCategory /> },
          ],
        },
        {
          path: "merchant",
          children: [
            { index: true, element: <MerchantManagement /> },
            { path: "add", element: <AddMerchant /> },
            { path: "update/:id", element: <EditMerchant /> },

          ],
        },
        {
          path: "user",
          children: [
            { index: true, element: <UserManagement /> },
            { path: "add", element: <AddUser /> },
            { path: "edit/:id", element: <EditUser /> },
          ],
        },
        {
          path: "email-template",
          children: [
            { index: true, element: <EmailTemplateManagement /> },
            { path: "add", element: <AddEmailTemplate /> },
            { path: "edit/:id", element: <EditEmailTemplate /> },
          ],
        },
        {
          path: "notification-management",
          children: [
            { index: true, element: <NotificationList /> },
            { path: "add", element: <AddEmailTemplate /> },
            { path: "edit/:id", element: <EditEmailTemplate /> },
          ],
        },
        {
          path: "complaint-management",
          children: [
            { index: true, element: <ComplaintList /> },
            { path: "add", element: <AddEmailTemplate /> },
            { path: "edit/:id", element: <EditEmailTemplate /> },
          ],
        },
        {
          path: "feedback",
          children: [
            { index: true, element: <FeedbackListing /> },

          ],
        },
        {
          path: "coupon-published",
          children: [
            { index: true, element: <CouponPublishedList /> },
            { path: "edit/:id", element: <CouponPublishedEdit /> },
          ],
        },
        {
          path: "coupon-templates",
          children: [
            { index: true, element: <CouponTemplateList /> },
            { path: "add", element: <CouponTemplateEdit /> },
            { path: "edit/:id", element: <CouponTemplateEdit /> },
          ],
        },
        { path: "*", element: <Navigate to="/dashboard" /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];

  const PublicRoutes = [
    {
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "login", element: <LoginPage /> },
        { path: "forgot", element: <ForgetForm /> },
        { path: "reset-password/:otp", element: <ResetPassword /> },
      ],
    },
    { path: "*", element: <Navigate to="/login" replace /> },
  ];

  const routes = useRoutes(isLoggedIn ? PrivateRoutes : PublicRoutes);

  return routes;
}
