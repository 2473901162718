import React, { useEffect, useState } from "react";
import { EditOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import DataTable from "react-data-table-component";
import errorHandler from "src/utils/errorHandler";
import { changeStatusApi, getAllPublishedCoupon } from "src/api/couponPublishedServices";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import CustomLoader from "src/components/CustomLoader";
import SwitchBtn from "src/components/SwitchBtn";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import View from "./View";
import { FILE_URL, STATUS_OPTIONS } from "src/utils/constants";
import TableFilter from "src/components/shared/TableFilter";
import { formatDate } from "src/utils/helpers";


const CouponPublishedList = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [paginData, setPaginData] = useState({
        data: [],
        totalCount: 0,
    })
    const [payload, setPayload] = useState({
        pageSize: 5,
        pageNumber: 1,
        searchItem: ""
    })
    const [viewModal, setViewModal] = useState({
        show: false,
        id: ""
    })
    const [sort, setSort] = useState({
        sortBy: "",
        asc: 1   // 1 == acs 0 == desc
    })
    const [status, setStatus] = useState([]);


    const fetchPublishedCouponList = async () => {
        try {
            setLoading(true)

            payload.status = status;
            payload.sortBy = sort.sortBy;
            payload.sortOrder = sort.asc;
            payload.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            payload.currentDate = new Date()
            let res = await getAllPublishedCoupon(payload)
            setPaginData({
                data: res?.data?.data?.result,
                totalCount: res?.data?.data?.totalRecords
            })

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPublishedCouponList()
    }, [payload, status, sort])

    const columns = [
        {
            name: "Coupon Image",
            selector: (row) =>
                <Avatar
                    style={{ height: "50px", width: "50px", margin: "7px 0px" }}
                    alt={"coupon image"}
                    src={FILE_URL + row?.couponImage}
                />
        },
        {
            name: "Title",
            selector: (row) => row?.title,
            sortable: true,
            sortField: 'title',

        },
        {
            name: "Merchant Name",
            selector: (row) => row?.businessName,
            sortable: true,
            sortField: 'businessName',
        },
        {
            name: "Discount Percentage",
            selector: (row) => row?.disPercentage,
            sortable: true,
            sortField: 'disPercentage',
        },

        {
            name: "List Price",
            selector: (row) => `$${row?.price}`,
            sortable: true,
            sortField: 'price',
        },

        {
            name: "Published Date",
            selector: (row) => formatDate(row?.createdAt, "DD/MM/YYYY hh:mm a"),
            // selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY hh:mm a"),
            sortable: true,
            sortField: 'createdAt',
        },
        {
            name: "End Date",
            selector: (row) => formatDate(row?.endDateTime, "DD/MM/YYYY"),
            // selector: (row) => moment(row?.endDateTime).format("DD/MM/YYYY"),
            sortable: true,
            sortField: 'endDateTime',
        },
        {
            name: "Availability",
            selector: (row) => row?.availability ? row?.availability : "-",
        },
        {
            name: "Status",
            selector: (row) => <SwitchBtn status={row.status} id={row?.id} apiUrl={changeStatusApi} callBack={fetchPublishedCouponList} />
        },
        {
            name: <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>,
            cell: (row) => (
                <>
                    <Tooltip title="Edit" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigate(`/coupon-published/edit/${row.id}`);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View" placement="top" leaveDelay={50}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setViewModal({
                                    show: true,
                                    id: row.id
                                })
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ]

    const handleSort = (e, sortDirection) => {
        setSort({
            sortBy: e.sortField,
            asc: sortDirection
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Coupon Published"
                ></CardHeader>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <TableFilter
                        data={STATUS_OPTIONS}
                        value={status}
                        setValue={setStatus}
                        filterName="Status"
                    />
                    <Input
                        label="Search"
                        variant="standard"
                        value={payload?.searchItem}
                        placeholder="Search"
                        onChange={(e) => setPayload({ ...payload, searchItem: e.target.value })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear-search"
                                    onClick={() => setPayload({ ...payload, searchItem: "" })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        }

                    />

                </Box>

                <CardContent>
                    <DataTable
                        columns={columns}
                        data={paginData.data}
                        pagination
                        paginationServer
                        paginationTotalRows={paginData.totalCount}
                        onChangePage={(page) => setPayload({ ...payload, pageNumber: page })}
                        paginationPerPage={payload.pageSize}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        onSort={handleSort}
                        sortServer
                        // paginationTotalRows={data ? data.length : 0}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                            setPayload({ ...payload, pageSize: currentRowsPerPage, pageNumber: currentPage });
                        }}
                    />
                </CardContent>
            </Card>
            {viewModal.show ?
                <View show={viewModal.show} onHide={() => setViewModal({ show: false, id: "" })} id={viewModal.id} />
                : null}
        </>
    )
}

export default CouponPublishedList