
import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    FormControl,
    FormHelperText,
    FormControlLabel,
    Radio,
    FormLabel,
    RadioGroup
} from "@mui/material";
import { ErrorMessage, Form, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { Loader } from 'src/components/CustomLoader';
import { useNavigate, useParams } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { addToFixedIfDecimal, handleMultipleImage, handleNumberInput, handleSingleImage } from 'src/utils/helpers';
import CancelIcon from '@mui/icons-material/Cancel';
import couponTemplateServices from 'src/api/couponTemplateServices';
import errorHandler from 'src/utils/errorHandler';
import { getCategories } from 'src/api/merchantServices';
import { FILE_URL } from 'src/utils/constants';
import { toast } from 'react-toastify';
import AddEditBtn from 'src/components/addEditBtn';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const GalleryImageCon = styled('div')({
    display: "flex",
    flexWrap: "wrap"

});

const CouponTemplateEdit = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [couponImgPreview, setCouponImgPreview] = useState("")
    const [galleryImgPreview, setGalleryImgPreview] = useState([])
    const [oldGalleryImgPreview, setOldGalleryImgPreview] = useState([])

    const [allMerchant, setAllMerchant] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            imagePath: "merchant/coupon",
            title: "",
            categoryId: "",
            description: "",
            duration: "30",
            durationText: "Minutes",
            price: "",
            disPercentage: "",
            discountedPrice: "",
            merchantId: "",
            couponImage: "",
            galleryImages: [],
            oldCouponImage: "",
            oldGalleryImages: [],
            deletedGalleryImages: []
        },
        validationSchema: Yup.object({
            couponImage: Yup.string().when('oldCouponImage', {
                is: (oldCouponImage) => !oldCouponImage || oldCouponImage.length === 0,
                then: () => Yup.string().required("Coupon image is required").min(1, "Coupon image is required"),
                otherwise: () => Yup.string()
            }),
            // galleryImages: Yup.array().when('oldGalleryImages', {
            //     is: (oldGalleryImages) => oldGalleryImages && oldGalleryImages.length === 0,
            //     then: () => Yup.array().required("Gallery image are required").min(1, "Gallery image are required"),
            //     otherwise: () => Yup.array()
            // }),
            title: Yup.string().required("Title is required").trim().max(150, "Title should be less than 150 characters"),
            categoryId: Yup.string().required("Category is required"),
            description: Yup.string().required("Description is required").trim().max(500, "Description should be less than 500 characters"),
            duration: Yup.string().required("Duration is required").max(6, "Duration should be less than 6 digits").test("duration", "Duration cannot be 0", (value) => {
                if (Number(value) > 0) {
                    return true
                } else {
                    return false
                }
            }),
            durationText: Yup.string().required("Duration text is required"),
            price: Yup.string().required("Price is required"),
            disPercentage: Yup.number().required("Discount percentage is required").max(100, "Discount Percentage should be less than 100").min(1, "Discount percentage should be greater than 0"),
            merchantId: Yup.string().required("Merchant is required"),

        }),
        onSubmit: (values, { setSubmitting }) => {
            handleFormSubmit(values, setSubmitting)

        }
    })

    const handleFormSubmit = async (values, setSubmitting) => {
        const formData = new FormData()
        formData.append("imagePath", values.imagePath)
        formData.append("title", values.title)
        formData.append("categoryId", values.categoryId)
        formData.append("description", values.description)
        formData.append("duration", values.duration)
        formData.append("durationText", values.durationText)
        formData.append("price", parseFloat(values.price).toFixed(2))
        formData.append("disPercentage", values.disPercentage)
        formData.append("discountedPrice", addToFixedIfDecimal((values.disPercentage * values.price) / 100))
        formData.append("merchantId", values.merchantId)


        //dont append couponImage if coupon image is not changed in edit page
        if (values.couponImage) {
            formData.append("couponImage", values.couponImage)
        }
        for (const galleryImage of values.galleryImages) {
            formData.append("galleryImages", galleryImage);
        }

        // add (id, oldCouponImage, oldGalleryImages) key when id present 
        if (id) {
            formData.append("id", values.id)
            // formData.append("oldCouponImage", values.oldCouponImage)
            for (const i of values.oldGalleryImages) {
                formData.append("oldGalleryImages[]", i);
            }
            for (const i of values.deletedGalleryImages) {
                formData.append("deletedGalleryImages[]", i);
            }
        }



        try {
            let res = id ? await couponTemplateServices.updateCouponTemplate(formData) : await couponTemplateServices.addCouponTemplate(formData)
            toast.success(res?.data?.message, {
                toastId: "coupon-template-added"
            })
            navigate("/coupon-templates")
        } catch (error) {
            errorHandler(error)
        } finally {
            setSubmitting(false)
        }

    }


    const { values, errors, touched, isSubmitting, getFieldProps, setFieldValue } = formik;

    const handleRemove = (id, old, url) => {
        if (old) {
            let newPreviewImage = oldGalleryImgPreview?.filter((_, idx) => id != idx)
            setOldGalleryImgPreview(newPreviewImage)
            let newFormikImage = formik.values.oldGalleryImages?.filter((_, idx) => id != idx)
            formik.setFieldValue("oldGalleryImages", newFormikImage)
            formik.setFieldValue("deletedGalleryImages", [...values.deletedGalleryImages, url])

        } else {
            let newPreviewImage = galleryImgPreview?.filter((_, idx) => id != idx)
            setGalleryImgPreview(newPreviewImage)
            let newFormikImage = formik.values.galleryImages?.filter((_, idx) => id != idx)
            formik.setFieldValue("galleryImages", newFormikImage)
        }
    }

    const fetchAllMerchant = async () => {
        try {
            let res = await couponTemplateServices.getAllMerchant()
            setAllMerchant(res?.data?.data)
        } catch (error) {
            errorHandler(error)
        }
    }

    const fetchCategoryByMerchantId = async (id) => {
        try {
            let res = await couponTemplateServices.getCategoryByMerchantId(id)
            setAllCategories(res?.data?.data)
        } catch (error) {
            errorHandler(error)
        }
    }

    useEffect(() => {
        fetchAllMerchant()

    }, [])


    const fetchCouponDetails = async () => {
        try {
            setLoading(true)
            let res = await couponTemplateServices.getCouponTemplate(id)
            let data = res?.data?.data

            formik.setFieldValue("title", data?.title)
            formik.setFieldValue("categoryId", data?.categoryId)
            formik.setFieldValue("description", data?.description)
            formik.setFieldValue("duration", data?.duration)
            formik.setFieldValue("durationText", data?.durationText)
            formik.setFieldValue("price", data?.price)
            formik.setFieldValue("discountedPrice", addToFixedIfDecimal((data?.disPercentage / data?.price) * 100))
            formik.setFieldValue("disPercentage", data?.disPercentage)
            formik.setFieldValue("merchantId", data?.merchantId)
            formik.setFieldValue("oldCouponImage", data?.couponImage || [])
            formik.setFieldValue("oldGalleryImages", data?.galleryImages || [])
            fetchCategoryByMerchantId(data?.merchantId)

            if (data?.couponImage) {
                setCouponImgPreview(FILE_URL + data?.couponImage)
            }
            if (data?.galleryImages) {
                setOldGalleryImgPreview(data?.galleryImages)
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchCouponDetails()
        }
    }, [id])


    return (
        <>
            <Card>
                <CardContent>
                    <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            {id ? "Edit" : "Add"} Coupon Template
                        </Typography>
                        {loading ? <Loader /> :
                            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2} mt={3}>


                                    <Grid item md={6} xs={12} mb={1} >
                                        <Stack direction="row" spacing={2}>
                                            <Avatar alt={"coupon image"} src={couponImgPreview} />
                                            <Button component="label" variant="outlined" style={{ backgroundColor: "#FFF" }} startIcon={<CloudUploadIcon />}>
                                                Upload Coupon Image
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    accept='image/*'
                                                    onChange={(e) => { handleSingleImage(e, "couponImage", formik, setCouponImgPreview) }}
                                                />

                                            </Button>
                                        </Stack>
                                        <ErrorMessage name='couponImage' className='form-error' component={"span"} />
                                    </Grid>

                                    <Grid item md={6} xs={12} mb={1} >
                                        <Stack direction="row" spacing={2} flexWrap={"wrap"} gap={1}>

                                            {oldGalleryImgPreview?.length > 0 ?
                                                oldGalleryImgPreview?.map((img, k) => (
                                                    <div className='couponTemplate'>
                                                        <Avatar alt={"gallery image"} src={FILE_URL + img} key={k} />
                                                        <CancelIcon className='close_icon' onClick={() => handleRemove(k, "old", img)} />
                                                    </div>
                                                ))
                                                : ""
                                            }
                                            {galleryImgPreview?.length > 0 ?
                                                galleryImgPreview?.map((img, k) => (
                                                    <div className='couponTemplate'>
                                                        <Avatar alt={"gallery image"} src={img} key={k} />
                                                        <CancelIcon className='close_icon' onClick={() => handleRemove(k)} />
                                                    </div>
                                                ))
                                                : ""
                                            }

                                            {oldGalleryImgPreview?.length > 0 || galleryImgPreview?.length > 0 ? "" : <Avatar alt={"gallery image"} src={""} />}

                                            {/* <Button component="label" variant="outlined" style={{ backgroundColor: {id ? "red" : "#FFF"} }} startIcon={<CloudUploadIcon />}> */}
                                            <Button component="label" variant="outlined" style={{ backgroundColor: "#FFF" }} startIcon={<CloudUploadIcon />}>
                                                Upload Gallery Images
                                                <VisuallyHiddenInput
                                                    multiple
                                                    type="file"
                                                    accept='image/*'
                                                    onChange={(e) => { handleMultipleImage(e, "galleryImages", formik, galleryImgPreview, setGalleryImgPreview, oldGalleryImgPreview) }}
                                                />
                                            </Button>
                                        </Stack>
                                        <ErrorMessage name='galleryImages' className='form-error' component={"span"} />
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            fullWidth
                                            name="title"
                                            label="Title"
                                            {...getFieldProps('title')}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <FormControl fullWidth error={Boolean(touched.merchantId && errors.merchantId)}>
                                            <InputLabel id="demo-simple-select-label">Merchant</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.merchantId}
                                                label="Merchant"
                                                onChange={(e) => {
                                                    setFieldValue("merchantId", e?.target?.value)
                                                    setFieldValue("categoryId", "")
                                                    fetchCategoryByMerchantId(e?.target?.value)
                                                }}
                                                name='merchantId'
                                            >
                                                {allMerchant?.map((merc) => (
                                                    <MenuItem value={merc?.id} key={merc?.id}>{merc?.businessName}</MenuItem>
                                                ))}
                                            </Select>
                                            {touched.merchantId && errors.merchantId && (
                                                <FormHelperText error className='form-error'> {errors.merchantId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <FormControl fullWidth error={Boolean(touched.categoryId && errors.categoryId)}>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.categoryId}
                                                label="Category"
                                                onChange={(e) => setFieldValue("categoryId", e?.target?.value)}
                                            >
                                                {allCategories?.map((cat) => (
                                                    <MenuItem value={cat?.categoryId} key={cat?.id}>{cat?.name}</MenuItem>
                                                ))}

                                            </Select>
                                            {touched.categoryId && errors.categoryId && (
                                                <FormHelperText error className='form-error'> {errors.categoryId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>



                                    <Grid item md={6} xs={12} >
                                        <Grid container spacing={3}>


                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Service Duration"
                                                    {...getFieldProps('duration')}
                                                    onChange={e => handleNumberInput(e, "duration", formik)}
                                                    error={Boolean(touched.duration && errors.duration)}
                                                    helperText={touched.duration && errors.duration}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl>

                                                    <RadioGroup
                                                        row
                                                        style={{ marginTop: "8px" }}
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        {...getFieldProps("durationText")}
                                                    >
                                                        <FormControlLabel value="Minutes" control={<Radio />} label="Minutes" />
                                                        <FormControlLabel value="Hours" control={<Radio />} label="Hours" />

                                                    </RadioGroup>
                                                    {touched.durationText && errors.durationText && (
                                                        <FormHelperText error className='form-error'> {errors.durationText}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="List Price"
                                            {...getFieldProps('price')}
                                            onChange={e => handleNumberInput(e, "price", formik)}
                                            error={Boolean(touched.price && errors.price)}
                                            helperText={touched.price && errors.price}
                                        />
                                    </Grid>


                                    {/* <Grid item md={6} xs={12} > */}
                                    {/* <FormControl fullWidth error={Boolean(touched.durationText && errors.durationText)}>
                                            <InputLabel id="demo-simple-select-label">Duration Text</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.durationText}
                                                label="Duration Text"
                                                onChange={(e) => setFieldValue("durationText", e.target?.value)}
                                            >
                                                <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                <MenuItem value={"Hours"}>Hours</MenuItem>
                                            </Select>
                                            {touched.durationText && errors.durationText && (
                                                <FormHelperText error className='form-error'> {errors.durationText}</FormHelperText>
                                            )}

                                        </FormControl> */}

                                    {/* </Grid> */}

                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            fullWidth
                                            label="Discount Percentage"
                                            {...getFieldProps('disPercentage')}
                                            onChange={e => handleNumberInput(e, "disPercentage", formik, true)}
                                            error={Boolean(touched.disPercentage && errors.disPercentage)}
                                            helperText={touched.disPercentage && errors.disPercentage}
                                        />
                                    </Grid>

                                    <Grid item xs={12}  >
                                        <TextField
                                            rows={5}
                                            multiline
                                            fullWidth
                                            label="Description"
                                            {...getFieldProps('description')}
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                    </Grid>

                                </Grid>

                                <AddEditBtn id={id} isSubmitting={isSubmitting} navigateUrl={"/coupon-templates"} />

                            </Form>
                        }
                    </FormikProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default CouponTemplateEdit