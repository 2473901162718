import { TextField } from '@mui/material';
import React, { useState } from 'react'
import { LoadScript, Autocomplete } from '@react-google-maps/api';



const GoogleAutoCompleteComp = ({ label, formikName, formik }) => {

    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    }


    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const places = autocomplete.getPlace()
            formik.setFieldValue([formikName], places?.formatted_address)
            formik.setFieldValue("lng", String(places?.geometry.location.lng()));
            formik.setFieldValue("lat", String(places?.geometry.location.lat()));

            formik.setFieldError([formikName], "");
            formik.setFieldError("lat", "");
            formik.setFieldError("lng", "");
        }
    }
    const { touched, errors } = formik


    return (
        <>


            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                libraries={["places"]}
            >
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                >
                    <TextField
                        fullWidth
                        label="Address *"
                        placeholder=""
                        value={formik.values.address}
                        onChange={e => {
                            formik.setFieldValue("address", e?.target.value)
                            formik.setFieldValue("lat", "")

                        }}
                        onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                        error={Boolean(Boolean(touched[formikName] && errors[formikName]) || Boolean(touched.lat && errors.lat))}
                    />
                </Autocomplete>
            </LoadScript>
            {Boolean((formik.touched[formikName] && formik.errors[formikName]) || (formik.touched.lat && formik.errors.lat)) && <span className='error-msg'> Address is required</span>}
        </>
    )
}

export default GoogleAutoCompleteComp