import AxiosInterceptor from "../utils/AxiosInterceptor";

const cmsService = {
  getOneCms: (values) => AxiosInterceptor().post(`cms/getOneCms`, values),
  updateCms: (values) => AxiosInterceptor().post(`cms/updateCms`, values),
  getAllFaqs: (values) => AxiosInterceptor().post(`cms/getAllFaqs`, values),
  addFaq: (values) => AxiosInterceptor().post(`cms/addFaq`, values),
  changeStatus: (values) => AxiosInterceptor().post(`cms/changeStatus`, values),
  getOneFaq: (values) => AxiosInterceptor().get(`cms/getOneFaq/${values}`),
  deleteFaq: (values) => AxiosInterceptor().get(`cms/deleteFaq/${values}`),
};


export default cmsService;
