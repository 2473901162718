import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import errorHandler from 'src/utils/errorHandler'
import * as Yup from "yup"
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Loader } from 'src/components/CustomLoader';
import complaintApi from 'src/api/complaintService';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';



const AddComplaint = ({ id, hideButton, setShowCommentModal, newStatus, callBack }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [show, setShow] = useState(false)


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            comment: ""
        },
        validationSchema: Yup.object({
            comment: Yup.string().required("Comment is required").min(5, "Comment must have atleast 5 characters").max(250, "Comment can have atmost 250 characters").trim()
        }),
        onSubmit: (values, { setSubmitting }) => {
            addCompaint(values, setSubmitting)
        }
    })

    const handleSwitch = async () => {

        try {
            let body = {
                id: id,
                status: String(newStatus)
            }
            const res = await complaintApi.changeStatus(body)
            toast.success(res?.data?.message)

            callBack()
        } catch (error) {
            errorHandler(error)
        }

    }

    const addCompaint = async (values, setSubmitting) => {
        try {
            const res = await complaintApi.addComplaintComment(values);
            // toast.success(res?.data?.message)

            if (hideButton) {
                setShowCommentModal(false)
                handleSwitch()
            }

            setShow(false)
            formik.resetForm()
        }
        catch (error) {
            errorHandler(error)
        }
        finally {
            setSubmitting(false)
        }
    }


    const onHide = () => {
        if (!formik.isSubmitting) {

            setShow(false)
            formik.resetForm()
            if (hideButton) {
                setShowCommentModal(false)
            }
        }
    }

    useEffect(() => {
        if (hideButton)
            setShow(true)
    }, [hideButton])

    return (
        <>
            {!hideButton ? <Tooltip title="Add Comment" placement="top">
                <IconButton
                    color='primary'
                    onClick={() => setShow(true)}
                >
                    <SendIcon />
                </IconButton>
            </Tooltip> : ""}

            <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
                <DialogTitle as="h2">Add Comment</DialogTitle>

                <form onSubmit={formik.handleSubmit}>
                    <DialogContent dividers>
                        <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }} mb={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="comment"
                                    label="Comment"
                                    {...formik.getFieldProps('comment')}
                                    error={Boolean(formik.touched.comment && formik.errors.comment)}
                                    helperText={formik.touched.comment && formik.errors.comment}
                                />
                            </Grid>

                        </Grid>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </Button>

                            <Button
                                variant="outlined"
                                onClick={() => onHide()}
                                type="button"
                                style={{ backgroundColor: "white" }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>

            </Dialog>
        </>
    )
}

export default AddComplaint