// export const API_BASE = 'https://staging-api.instacoupon.com/admin/';
// export const FILE_URL = 'https://staging-api.instacoupon.com/';

export const API_BASE = 'https://instacouponapipg.devtechnosys.tech/admin/';
export const FILE_URL = 'https://instacouponapipg.devtechnosys.tech/';

// export const API_BASE = "http://172.16.1.250:17178/admin/";
// export const FILE_URL = 'http://172.16.1.250:17178/';

// export const BASE_API = API_BASE + "/api";
// export const MAIN_URL = BASE_API + "/api/admin";

export const MOBILE_REGEX = /^[0-9]{10,15}$/;

export const PASSWORDS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?.])[A-Za-z\d#$@!%^&*?.]{8,}$/g;

// export const EMAIL_REGEX = /^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;


export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE_REGEX_EMAIL = /^(?=.{1,80}$)([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/


export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/gif", "video/mp4", "video/x-ms-wmv", "video/quicktime", "video/webm", "application/pdf", "text/plain", "application/msword", "application/vnd.ms-excel", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

export const GENDER_OPTIONS = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Prefer not to say", label: "Prefer not to say" },
]

export const STATUS_OPTIONS = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" }
]


export const COMPLAINT_OPTIONS = [
    { label: "Pending", value: 0 },
    { label: "Resolved", value: 1 },
    { label: "Declined", value: 2 },
]

export const COMPLAINT_ROLES = [
    { label: "-", value: "" },
    { label: "User", value: "2" },
    { label: "Merchant", value: "3" }
]