import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormGroup,
    Checkbox
} from "@mui/material";
import { ErrorMessage, Form, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { Loader } from 'src/components/CustomLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { handleNumberInput } from 'src/utils/helpers';
import { getPublishedCoupon, updatePublishedCoupon } from 'src/api/couponPublishedServices';
import { toast } from 'react-toastify';
import errorHandler from 'src/utils/errorHandler';
import AddEditBtn from 'src/components/addEditBtn';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import moment from 'moment';



const CouponPublishedEdit = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [couponData, setCouponData] = useState({})

    const [openEnd, setOpenEnd] = useState(false);


    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            endDateTime: "",
            numberOfSlots: "",
            dailyShow: "",
            weeklyShow: []
        },
        validationSchema: Yup.object({
            endDateTime: Yup.string().required("End date is required"),
            numberOfSlots: Yup.number().required("Number of slots is required").min(1, "Minimum 1 slot is required").max(100, "Maximum 100 slots are allowed"),
            dailyShow: Yup.string().required("Show is required"),
            weeklyShow: Yup.array().when("dailyShow", {
                is: (dailyShow) => dailyShow == "false",
                then: () => Yup.array().required("Weeks are required").test(["endDateTime , dailyShow", "weeklyShow"], (values, data) => {

                    if (moment(couponData?.startDateTime).format('YYYY-MM-DD') != moment(formik.values.endDateTime).format('YYYY-MM-DD')) {
                        let daysInRange = getDaysInRange(couponData?.startDateTime, data.parent.endDateTime)?.map((day) => weekdays[day])

                        if (values.length == 0) {
                            return data.createError({ message: "Weeks are required" })
                        }

                        let check = values?.find((value) => (!daysInRange?.includes(value)))
                        if (check?.length > 0) {
                            return data.createError({ message: "Selected days are not present in between start date and end date" })
                        } else {
                            return true
                        }
                    } else {
                        return true
                    }


                }),
                else: Yup.array()
            }),
        }),
        onSubmit: (values, { setSubmitting }) => {
            handleFormSubmit({ ...values }, setSubmitting)
        }
    })


    function getDaysInRange(startDate, endDate) {
        const days = [];
        const currentDate = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 0);

        while (currentDate <= end) {
            days.push(currentDate.getDay()); // Push the day of the week (0-6)
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return days;
    }



    const handleFormSubmit = async (values, setSubmitting) => {
        try {

            values.id = id
            values.endDateTime = new Date(values?.endDateTime)?.toISOString()

            if (values.dailyShow == "true") {
                values.weeklyShow = []
            }
            let res = await updatePublishedCoupon(values)
            toast.success(res?.data?.message, {
                toastId: "coupon published updated"
            })
            navigate("/coupon-published");
        } catch (error) {
            errorHandler(error)
        } finally {
            setSubmitting(false)
        }
    }


    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


    const fetchCoupon = async () => {
        try {
            setLoading(true)
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            let { data: { data } } = await getPublishedCoupon(id, timezone)
            setCouponData(data)
            formik.setFieldValue("endDateTime", new Date(data?.endDateTime))
            formik.setFieldValue("numberOfSlots", data?.numberOfSlots)
            formik.setFieldValue("dailyShow", data?.dailyShow == true ? "true" : "false")
            formik.setFieldValue("weeklyShow", data?.weeklyShow || [])

        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchCoupon()
        }
    }, [id])



    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]


    const handleDays = (e) => {
        if (e?.target?.checked == true) {
            formik.setFieldValue("weeklyShow", [...formik.values.weeklyShow, e?.target?.value])
        } else {
            let temp = formik.values.weeklyShow?.filter((show) => show != e?.target?.value)
            formik.setFieldValue("weeklyShow", temp)
        }
    }






    return (
        <>
            <Card>
                <CardContent>
                    <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            Edit Coupon Published
                        </Typography>

                        {loading ? <Loader /> :
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2} mt={3} mb={2}>




                                    <Grid item lg={6} sm={12}>
                                        <FormControl fullWidth error={Boolean(touched.dob && errors.dob)}>
                                            <DatePicker
                                                disableFuture
                                                label="Start Date"
                                                defaultValue={new Date(couponData?.startDateTime)}
                                                onChange={(date) => console.log(date)}
                                                inputFormat="DD/MM/YYYY"
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} sm={12}>
                                        <FormControl fullWidth error={Boolean(touched.endDateTime && errors.endDateTime)}>
                                            <DatePicker
                                                label="End Date"
                                                value={values?.endDateTime}
                                                minDate={new Date(couponData?.startDateTime)}
                                                onChange={(e) => {
                                                    formik.setFieldValue("endDateTime", e)
                                                    if (moment(couponData?.startDateTime).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD')) {
                                                        formik.setFieldValue("dailyShow", "false")
                                                        formik.setFieldValue("weeklyShow", [])
                                                    }
                                                }}
                                                inputFormat="DD/MM/YYYY"
                                                open={openEnd}
                                                onOpen={() => setOpenEnd(true)}
                                                onClose={() => setOpenEnd(false)}
                                                slotProps={{
                                                    textField: {
                                                        readOnly: true,
                                                        onKeyDown: (e) => { e.preventDefault() },
                                                        helperText: touched.endDateTime && errors.endDateTime,
                                                        error: Boolean(touched.endDateTime && errors.endDateTime),
                                                        onClick: () => { setOpenEnd(true); }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>



                                    {moment(couponData?.startDateTime).format('YYYY-MM-DD') != moment(formik.values.endDateTime).format('YYYY-MM-DD')
                                        ?
                                        <>

                                            <Grid item sm={12} >
                                                <FormControl>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">Show</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        {...getFieldProps("dailyShow")}

                                                    >
                                                        <FormControlLabel value="true" control={<Radio />} label="Daily" />
                                                        <FormControlLabel value="false" control={<Radio />} label="Days Of Week" />

                                                    </RadioGroup>
                                                    <ErrorMessage name='dailyShow' component={"span"} className='error-msg' />
                                                </FormControl>
                                            </Grid>

                                            {formik.values.dailyShow == "false" ?
                                                <Grid item sm={12} >
                                                    <FormGroup row>
                                                        {weekdays?.map((day) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        value={day}
                                                                        checked={formik.values.weeklyShow?.includes(day)}
                                                                        onChange={e => handleDays(e)}

                                                                    />
                                                                } label={day}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                    <ErrorMessage name='weeklyShow' component={"span"} className='error-msg' />
                                                </Grid>
                                                : ""
                                            }
                                        </>
                                        : ""}

                                    <Grid item lg={6} sm={12} >
                                        <div className="coupon_timepicker">

                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer
                                                    components={['MobileTimePicker',]}
                                                >
                                                    <MobileTimePicker
                                                        label="Slot Start Time"
                                                        defaultValue={moment.utc(couponData?.slotStartTime, 'hh:mm a').toDate()}
                                                        slotProps={{ textField: { fullWidth: true } }}
                                                        disabled
                                                        onChange={e => console.log(e)}
                                                    />

                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} sm={12}  >

                                        <TextField
                                            style={{ marginTop: "8px" }}
                                            fullWidth
                                            label="Number Of Slots"
                                            {...getFieldProps('numberOfSlots')}
                                            onChange={e => handleNumberInput(e, "numberOfSlots", formik)}
                                            error={Boolean(touched.numberOfSlots && errors.numberOfSlots)}
                                            helperText={touched.numberOfSlots && errors.numberOfSlots}
                                        />
                                    </Grid>

                                </Grid>


                                <AddEditBtn id={id} isSubmitting={isSubmitting} navigateUrl={"/coupon-published"} />


                            </Form>
                        }
                    </FormikProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default CouponPublishedEdit