import AxiosInterceptor from "../utils/AxiosInterceptor";

const categoryService = {
  getOneCategory: (values) =>
    AxiosInterceptor().get(`category/getOneCategory/${values}`),
  updateCategory: (values) =>
    AxiosInterceptor().post(`category/updateCategory`, values),
  getAllCategories: (values) =>
    AxiosInterceptor().post(`category/getAllCategories`, values),
  addCategory: (values) =>
    AxiosInterceptor().post(`category/addCategory`, values),
  changeStatus: (values) =>
    AxiosInterceptor().post(`category/changeStatus`, values),
  deleteCategory: (values) =>
    AxiosInterceptor().get(`category/deleteCategory/${values}`),
};


export default categoryService;
