import React, { useEffect, useState } from 'react'
import complaintApi from 'src/api/complaintService'
import errorHandler from 'src/utils/errorHandler'
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Loader } from 'src/components/CustomLoader';
import { COMPLAINT_OPTIONS, COMPLAINT_ROLES } from 'src/utils/constants';
import { formatDate, showBusinessName, showNameByRoles } from 'src/utils/helpers';
import moment from 'moment';


const ViewComplaint = ({ id }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [show, setShow] = useState(false)

    const fetchComplaintDetials = async () => {
        try {
            setLoading(true)
            const response = await complaintApi.getSingleComplaint(id);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        if (id, show) {
            fetchComplaintDetials()
        }
    }, [id, show])

    const onHide = () => {
        setShow(false)
    }

    const getStatusValue = (value) => {
        return COMPLAINT_OPTIONS?.filter((comp) => comp.value == value)?.[0]?.label
    }

    return (
        <>
            <Tooltip title="View" placement="top">
                <IconButton
                    color='primary'
                    onClick={() => setShow(true)}
                >
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>


            <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
                <DialogTitle as="h2">Details</DialogTitle>
                {loading
                    ? <Loader />
                    : <DialogContent dividers>
                        <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Name</Typography>
                                <DialogContentText>
                                    {showNameByRoles(data?.role, data)}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Business Name</Typography>
                                <DialogContentText>
                                    {showBusinessName(data?.role, data)}
                                </DialogContentText>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Email</Typography>
                                <DialogContentText>
                                    {data?.email}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Phone Number</Typography>
                                <DialogContentText>
                                    {data?.phoneNumber || "-"}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Role</Typography>
                                <DialogContentText>
                                    {COMPLAINT_ROLES?.filter((roles) => roles?.value == data?.role)?.[0]?.label}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Status</Typography>
                                <DialogContentText>
                                    {COMPLAINT_OPTIONS?.filter((comp) => comp?.value == data?.status)?.[0]?.label}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Description</Typography>
                                <DialogContentText>
                                    {data?.description}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Complaint Date</Typography>
                                <DialogContentText>
                                    {formatDate(data?.createdAt, "DD/MM/YYYY hh:mm a")}
                                    {/* {data?.createdAt ? moment().format("DD/MM/YYYY hh:mm a") : ""} */}
                                </DialogContentText>
                            </Grid>

                            <Grid item xs={12} md={6} >
                                <Typography variant="subtitle1">Comment</Typography>
                                <DialogContentText>
                                    {data?.comment || "-"}

                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1">Commented By</Typography>
                                <DialogContentText>
                                    {data?.commentedByName || "-"}

                                </DialogContentText>
                            </Grid>




                        </Grid>
                        <DialogActions>

                            <Button
                                variant="outlined"
                                onClick={() => onHide()}
                                type="button"
                                style={{ backgroundColor: "white" }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                }
            </Dialog>
        </>
    )
}

export default ViewComplaint