import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import categoryService from "src/api/categoryService";
import { getOneTemplate } from "src/api/emailTemplateServices";
import { getMerchantDetail } from "src/api/merchantServices";
import { Loader } from "src/components/CustomLoader";
import errorHandler from "src/utils/errorHandler";
import { capitalize } from "src/utils/helpers";

const ViewEmailTemplate = ({ id, show, onHide }) => {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true)

    const userData = async () => {
        try {
            setLoading(true)
            const response = await getOneTemplate(id);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        userData();
    }, []);

    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Details</DialogTitle>
            {loading ? <Loader /> :
                <DialogContent dividers>
                    <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Title</Typography>
                            <DialogContentText>
                                {capitalize(data?.title)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Subject</Typography>
                            <DialogContentText>
                                {capitalize(data?.subject)}{" "}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle1">Content</Typography>
                            <DialogContentText className="editor-content-show">
                                {HtmlParser(data?.content)}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => onHide()}
                            type="submit"
                            style={{ backgroundColor: "white" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            }
        </Dialog>
    );
};

export default ViewEmailTemplate;
