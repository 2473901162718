import AxiosInterceptor from "../utils/AxiosInterceptor";

export const getAllTemplate = async (payload) => {
    return await AxiosInterceptor().post(`template/getAllTemplate`, payload);
};

export const addTemplate = async (payload) => {
    return await AxiosInterceptor().post(`template/addTemplate`, payload);
};

export const updateTemplate = async (payload) => {
    return await AxiosInterceptor().post(`template/updateTemplate`, payload);
};

export const getOneTemplate = async (id) => {
    return await AxiosInterceptor().get(`template/getOneTemplate/${id}`);
};

export const deleteTemplate = async (id) => {
    return await AxiosInterceptor().get(`template/deleteTemplate/${id}`);
};

export const changeStatus = async (payload) => {
    return await AxiosInterceptor().post(`template/changeStatus`, payload);
};

export const sendMail = async (payload) => {
    return await AxiosInterceptor().post(`template/sendMail`,payload);
};