import AxiosInterceptor from "src/utils/AxiosInterceptor"

export const changeStatusApi = "coupon/changeStatus"

const couponTemplateServices = {
    getAllPublishedCoupon: (payload) => AxiosInterceptor().post("coupon/getAllCouponTemplates", payload),
    getAllMerchant: () => AxiosInterceptor().get("coupon/getMerchants"),
    addCouponTemplate: (payload) => AxiosInterceptor().post("coupon/addCouponTemplate", payload),
    getCouponTemplate: (id) => AxiosInterceptor().get(`coupon/getOneCouponTemplate/${id}`),
    getCategoryByMerchantId: (id) => AxiosInterceptor().get(`coupon/getCategories/${id}`),
    updateCouponTemplate: (payload) => AxiosInterceptor().post("coupon/updateCouponTemplate", payload),
}


export default couponTemplateServices
