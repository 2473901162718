const { default: AxiosInterceptor } = require("src/utils/AxiosInterceptor");



const complaintApi = {
    getAllComplaint: (values) => AxiosInterceptor().post("complaint/getAllComplaints", values),
    addComplaintComment: (values) => AxiosInterceptor().post("complaint/addComment", values),
    changeStatus: (values) => AxiosInterceptor().post("complaint/changeStatus", values),
    getSingleComplaint: (id) => AxiosInterceptor().get(`complaint/getOneComplaintDetails/${id}`)
}


export default complaintApi